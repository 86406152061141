import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);

      this.$items = this.$('button');
      this.$results = this.$('result');

      this.events = {
          click: {
              'button': 'getCategory'
          }
      }
  
    }

    init() {
      this.updateResultData();
    }

    updateResultData() {
      for(let result of this.$results) {
        const dataEl =  this.$('data', result);
        const dataCat = this.getData('data-category', dataEl[0]);
        this.setData('category', dataCat, result)
      }
    }

    getCategory(e) {
      const target = e.currentTarget;
      const category = this.getData('category', target);
      for(let item of this.$items) {
        if (item.classList.contains('is-selected')) {
        item.classList.remove('is-selected');
        }
      }
      target.classList.add('is-selected');
      this.filterList(category);
    }

    filterList(e) {
      for(let result of this.$results) {
        const resultCat = this.getData('category', result);
        result.classList.add('is-hidden');
        if (e == 'all') {
        result.classList.remove('is-hidden');
        } else if (resultCat == e) {
          result.classList.remove('is-hidden');
        }
      }
    }

  destroy() {
      super.destroy()
  }
}
