import {
  module
} from 'modujs';
import {
  html
} from '../utils/classes'

export default class extends module {
  constructor(m) {
    super(m);

    this.events = {
      click: {
        open: 'openModal',
        close: 'closeModal'
      }
    }
  }

  init() {}

  openModal() {
    html.classlist.add('has-modal-open');
  }

  closeModal() {
    html.classlist.remove('has-modal-open');
  }
  destroy() {
    super.destroy()
  }
}